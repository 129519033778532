import { useContext } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import AccountManagement from "../components/pages/AccountManagement"
import AccountOpeningError from "../components/pages/AccountOpeningError"
import Banking from "../components/pages/Banking"
import ChangeAdress from "../components/pages/ChangeAddress"
import CreateDigitalSignature from "../components/pages/DigitalSignature/CreateDigitalSignature"
import Home from "../components/pages/Home"
import Onboarding from "../components/pages/Onboarding"
import WaitAccountOpen from "../components/pages/WaitAccountOpen"
import { AuthContext } from "../context/AuthProvider"
import OnboardingProvider from "../context/OnboardingContext"

const RootRoutes = () => {
  const { user } = useContext(AuthContext)

  const renderPrivate = (page) => {
    if (!user) return <Navigate to="/" />
    else if (user.status === 2 || user.aprovado === 2 || user.aprovado === 4)
      return <AccountOpeningError />
    else if (!user[`accounts`] || user[`accounts`].length === 0) {
      console.log(`Conta não aprovada ou em processo de abertura...`)
      return <WaitAccountOpen />
    } else {
      switch (page) {
        case `banking`:
          return <Banking component="home" />
        case `pix`:
          return <Banking component="pix" />
        case `transferir`:
          return <Banking component="transferir" />
        case `extrato`:
          return <Banking component="extrato" />
        case `boleto`:
          return <Banking component="boleto" />
        case `boletoManagerMobile`:
          return <Banking component="boletoManagerMobile" />
        case `pagar`:
          return <Banking component="pagar" />
        case `seguro`:
          return <Banking component="seguro" />
        case `cartao`:
          return <Banking component="cartao" />
        case `saque`:
          return <Banking component="saque" />
        case `account-management`:
          return <AccountManagement />
        case `digital-signature`:
          return <CreateDigitalSignature />
        case `change-address`:
          return <ChangeAdress />
      }
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          key="home"
          path="/"
          element={user ? <Navigate to="/banking" /> : <Home />}
        />
        <Route
          key="onboarding"
          path="/onboarding"
          element={
            <OnboardingProvider>
              <Onboarding />
            </OnboardingProvider>
          }
        />
        <Route
          key="waitAccount"
          path="/waitAccount"
          element={<WaitAccountOpen />}
        />
        <Route
          key="banking"
          path="/banking"
          element={renderPrivate(`banking`)}
        />
        <Route key="pix" path="/banking/pix" element={renderPrivate(`pix`)} />
        <Route
          key="transferir"
          path="/banking/transferir"
          element={renderPrivate(`transferir`)}
        />
        <Route
          key="extrato"
          path="/banking/extrato"
          element={renderPrivate(`extrato`)}
        />
        <Route
          key="boleto"
          path="/banking/boleto"
          element={renderPrivate(`boleto`)}
        />
        <Route
          key="boletoManagerMobile"
          path="/banking/boleto/manager/mobile"
          element={renderPrivate(`boletoManagerMobile`)}
        />
        <Route
          key="pagar"
          path="/banking/pagar"
          element={renderPrivate(`pagar`)}
        />
        <Route
          key="seguro"
          path="/banking/seguro"
          element={renderPrivate(`seguro`)}
        />
        <Route
          key="cartao"
          path="/banking/cartao"
          element={renderPrivate(`cartao`)}
        />
        <Route
          key="saque"
          path="/banking/saque"
          element={renderPrivate(`saque`)}
        />
        <Route
          key="account-management"
          path="/account-management"
          element={renderPrivate(`account-management`)}
        />
        <Route
          key="digital-signature"
          path="/digital-signature"
          element={renderPrivate(`digital-signature`)}
        />
        <Route
          key="change-address"
          path="/change-address"
          element={renderPrivate(`change-address`)}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default RootRoutes
