import { useContext } from "react"
import { OnboardingContext } from "../../../context/OnboardingContext"
import defaultLogo from "../../../assets/images/logo_kikkin_white.svg"
import { FieldValues, useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import { validateBirthday } from "../../../utils/validation"
import { ThemeContext } from "../../../context/ThemeContext"

const Birthday = () => {
  const { goBack, saveBirthday, birthday } = useContext(OnboardingContext)
  const { logo } = useContext(ThemeContext)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      birthday: birthday,
    },
  })

  const handleSaveBirthday = (data: FieldValues) => {
    saveBirthday(data.birthday)
  }

  return (
    <form
      className="onboarding-form-container"
      onSubmit={handleSubmit(handleSaveBirthday)}
    >
      <button className="onboarding-go-back" onClick={goBack} type="button" />

      <img
        style={{ marginTop: `120px` }}
        src={logo || defaultLogo}
        width="80px"
        height="27px"
      />
      <div className="onboarding-question-primary">
        Qual sua data de nascimento?
      </div>
      <InputMask
        className={
          errors?.birthday
            ? `onboarding-input input-invalid`
            : `onboarding-input`
        }
        placeholder="Digite sua data de nascimento"
        mask="99/99/9999"
        inputMode="numeric"
        {...register(`birthday`, {
          required: true,
          validate: validateBirthday,
        })}
      />
      {errors.birthday && (
        <div className="input-error">Data de nascimento inválida</div>
      )}
      <div className="flex-end">
        <button className="onboarding-btn-next" type="submit" />
      </div>
    </form>
  )
}

export default Birthday
