import { useContext, useState } from "react"
import { OnboardingContext } from "../../../context/OnboardingContext"
import OnboardingModal from "../../features/OnboardingModal"
import "./styles.scss"
import { useNavigate } from "react-router-dom"
import { useResposive } from "../../../hooks/useResponsive"

const Welcome = () => {
  const { goBack, startSteps } = useContext(OnboardingContext)

  const navigate = useNavigate()

  const [hasCamModal, setHasCamModal] = useState(false)
  const [qrCodeModal, setQrCodeModal] = useState(false)
  const { isTabletOrMobile } = useResposive()

  const handleWelcomeGoBack = () => {
    goBack()
    navigate(`/`)
  }

  const handleStart = () => {
    if (isTabletOrMobile) {
      startSteps()
    } else {
      setHasCamModal(true)
    }
  }

  return (
    <div className="gray-bg">
      <div className="corner-image-bg">
        <div className="dark-overlay">
          <div className="start-text-container">
            <button
              className="onboarding-go-back-white mt-60px"
              onClick={handleWelcomeGoBack}
            />
            <div className="start-text-title mt-md-5 mt-4 mb-3">
              Antes de começar, queremos te dar uma dica.
            </div>
            <div className="start-text-message mb-4">
              Vamos pedir um documento oficial com foto e um comprovante de
              residência, então que tal já separar?
            </div>
            <button className="start-button mb-3" onClick={handleStart}>
              Tudo pronto!
            </button>
          </div>
        </div>
      </div>
      <OnboardingModal
        isOpen={hasCamModal}
        onClose={() => setHasCamModal(false)}
      >
        <div>
          <div className="modal-title mb-2">{`Para iniciar...`}</div>
          <div className="modal-message">
            {`Você possui câmera nesse computador onde está se cadastrando ?`}
          </div>
          <div className="flex-end mt-3">
            <button
              className="onboarding-btn-primary yes-btn"
              onClick={() => startSteps()}
            >
              Sim
            </button>
            <button
              className="onboarding-btn-primary ml-3"
              onClick={() => {
                setHasCamModal(false)
                setQrCodeModal(true)
              }}
            >
              Não
            </button>
          </div>
        </div>
      </OnboardingModal>
      <OnboardingModal
        isOpen={qrCodeModal}
        onClose={() => setQrCodeModal(false)}
      >
        <div className="qr-code-container ">
          Para facilitar, faça seu cadastro por celular. Aponte sua camera para
          o código abaixo e comece agora :)
          <img
            src={`https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=${window.location.href}`}
            alt="qrcode"
            className="qr-code-image"
          />
        </div>
      </OnboardingModal>
    </div>
  )
}

export default Welcome
