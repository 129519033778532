import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"

dayjs.extend(customParseFormat)

export const validateCpf = (cpf: string) => {
  if (typeof cpf !== `string`) return false
  cpf = cpf.replace(/[\s.-]*/gim, ``)
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === `00000000000` ||
    cpf === `11111111111` ||
    cpf === `22222222222` ||
    cpf === `33333333333` ||
    cpf === `44444444444` ||
    cpf === `55555555555` ||
    cpf === `66666666666` ||
    cpf === `77777777777` ||
    cpf === `88888888888` ||
    cpf === `99999999999`
  ) {
    return false
  }
  let soma = 0
  let resto

  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)

  resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) resto = 0
  if (resto !== parseInt(cpf.substring(9, 10))) return false

  soma = 0

  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)

  resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) resto = 0
  if (resto !== parseInt(cpf.substring(10, 11))) return false

  return true
}

export const validateBirthday = (birthday: string) => {
  const eighteenYearsAgo = dayjs().subtract(18, `years`)
  const year = parseInt(birthday.substring(6, 10))
  const month = parseInt(birthday.substring(3, 5))
  const day = parseInt(birthday.substring(0, 2))
  const fixedDate = new Date(year, month, day)

  const reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/
  if (!birthday.match(reg)) return false
  else if (!eighteenYearsAgo.isAfter(fixedDate)) {
    return false
  }
  return true
}

export const validatePhone = (phone = ``) => {
  if (phone.includes(`_`)) {
    return false
  }
  return true
}

export const validateCNPJ = (cnpj: string) => {
  cnpj = cnpj.replace(/[^\d]+/g, ``)

  if (cnpj == ``) return false

  if (cnpj.length != 14) return false

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == `00000000000000` ||
    cnpj == `11111111111111` ||
    cnpj == `22222222222222` ||
    cnpj == `33333333333333` ||
    cnpj == `44444444444444` ||
    cnpj == `55555555555555` ||
    cnpj == `66666666666666` ||
    cnpj == `77777777777777` ||
    cnpj == `88888888888888` ||
    cnpj == `99999999999999`
  )
    return false

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros: any = cnpj.substring(0, tamanho)
  const digitos: any = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado != digitos.charAt(0)) return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado != digitos.charAt(1)) return false

  return true
}

export const validateCpfOrCNPJ = (text: string) => {
  const isValidCpf = validateCpf(text)
  if (isValidCpf) return true
  else {
    const isValidCnpj = validateCNPJ(text)

    if (isValidCnpj) return true
    else return false
  }
}

export const price_to_number = (v: string) => {
  console.log(`🚀 ~ file: validation.ts ~ line 128 ~ v`, v)
  if (!v) {
    return 0
  }
  v = v.split(`.`).join(``)
  v = v.split(`,`).join(`.`)
  return Number(v.replace(/[^0-9.]/g, ``))
}

export const validateCurrency = (value: string) => {
  const fixedValue = price_to_number(value)
  if (fixedValue > 0) return true
  else return false
}

export const formatCpf = (cpf = ``) => {
  return (
    cpf.substring(0, 3) +
    `.` +
    cpf.substring(3, 6) +
    `.` +
    cpf.substring(6, 9) +
    `-` +
    cpf.substring(9, 11)
  )
}

export const validateEmail = (email = ``) => {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

export const validateEvp = (evp: string) => {
  const re =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  return re.test(evp)
}

export const formatCnpj = (cnpj: string) => {
  return `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(
    5,
    8,
  )}/${cnpj.substring(8, 12)}-${cnpj.substring(12, 14)}`
}

export const formatPhone = (phone = ``) => {
  return `(${phone.substring(0, 2)}) ${phone.substring(2, 7)}-${phone.substring(
    7,
    11,
  )}`
}

export const unformatCpf = (cpf: string) => {
  return cpf.replace(/[.-]/g, ``)
}

export const censorCpf = (cpf: string) => {
  return (
    `***` + `.` + cpf.substring(3, 6) + `.` + cpf.substring(6, 9) + `-` + `**`
  )
}

export const getDigitsOnly = (txt = ``) => {
  return txt.replace(/\D/g, ``)
}

export const formatCep = (cep: any) =>
  `${cep.substring(0, 5)}-${cep.substring(5, 8)}`
