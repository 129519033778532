import "./styles.scss"
import Card from "k4n-cards-mfe"
import { AuthContext } from "../../../../context/AuthProvider"
import { useContext, useEffect, useState } from "react"
import { Route } from "react-router-dom"
import * as webUtils from "../../../../utils"

const Cards = () => {
  const { user } = useContext(AuthContext)
  const [routes, setRoutes] = useState([])

  const context = {
    userContextData: user,
    webUtils,
  }

  const getRoutes = (routes) => {
    setRoutes(routes)
  }

  useEffect(() => {
    console.log(routes)
  }, [])

  return (
    <>
      {/* <Card routeInstance={Route} context={context} /> */}
      <Card context={context} />
    </>
  )
}

export default Cards
